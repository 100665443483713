<template>
  <div class="suggestion-card">
    <div class="suggestion-card__word">
      <span> {{ $attrs.suggestion.word }} </span>
    </div>
    <div class="suggestion-card__suggestions">
      <span> Variações sugeridas: </span>
      <div class="suggestion-card__checkboxes">
        <b-checkbox
          v-for="(checkbox, index) in checkboxes"
          :key="index"
          type="is-black"
          v-model="suggestionGroup"
          :native-value="checkbox"
        >
          {{ checkbox }}
        </b-checkbox>
      </div>
      <b-button
        v-if="!edit"
        @click="edit = true"
        class="suggestion-card__add-variation"
        icon-left="plus"
        size="small"
        type="is-ghost"
        >Adicionar outra variação</b-button
      >
      <b-field @keyup.native.enter="addVariation" class="ml-2" v-else>
        <b-input v-model="newVariation" size="is-small" />
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuggestionCard',
  data () {
    return {
      suggestionGroup: this.$attrs.suggestion.suggestions,
      checkboxes: this.$attrs.suggestion.suggestions,
      newVariation: null,
      edit: false
    }
  },
  watch: {
    suggestionGroup () {
      this.$emit('onUpdateVariations', {
        suggestionGroup: this.suggestionGroup,
        word: this.$attrs.suggestion.word
      })
    }
  },
  methods: {
    addVariation () {
      this.edit = false
      if (this.newVariation) {
        this.checkboxes.push(this.newVariation)
        this.suggestionGroup.push(this.newVariation)
      }
      this.newVariation = null
    }
  }
}
</script>

<style lang="scss" scoped>
.suggestion-card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 2rem 0;
  border: 1px solid lightgrey;

  &__word {
    font-weight: bold;
    font-size: 1.1rem;
  }

  &__suggestions {
    display: flex;
    margin-top: 1rem;
  }

  &__checkboxes {
    margin-left: 1rem;
  }

  &__add-variation {
    color: lightgrey !important;
    align-items: self-start;
    font-weight: bold;
    font-size: 0.9rem;
    margin-left: 1rem;
    padding: 0;

    &:focus {
      box-shadow: unset !important;
    }
  }
}
.b-checkbox.checkbox:not(.button) {
  margin-right: 1rem;
}
</style>
