<template>
  <div :class="`card card--${isActive ? 'active' : 'inactive'}`">
    <div class="is-flex is-justify-content-space-between">
      <div class="card__word">
        <span> {{ $attrs.word.word }} </span>
      </div>
      <div class="card__switcher-wrapper">
        <b-field horizontal label="Gerar variações">
          <b-switch v-model="isActive" type="is-dark">
          </b-switch>
        </b-field>
      </div>
    </div>
    <hr class="card__divider" />
    <div class="is-flex">
      <span> Entidade: </span>
      <div class="tag">
        <b-tag
          attached
          closable
          rounded
          v-if="entity"
          @close="deleteEntity"
        >
          {{ entity }}
        </b-tag>
        <b-taginput
          v-else
          attached
          :value="[]"
          rounded
          icon="plus"
          placeholder="Adicionar entidade"
          @add="addEntity"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WordCard',
  props: {
    words: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isActive: false,
      entity: null
    }
  },
  watch: {
    isActive (newValue) {
      this.$emit('onChangeSwitch', { value: newValue, ref: this.$attrs.word.word })
    },
    entity (newValue) {
      this.$emit('onUpdateEntity', { entity: newValue, ref: this.$attrs.word.word })
    }
  },
  methods: {
    addEntity (event) {
      this.entity = event
    },
    deleteEntity () {
      this.entity = null
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem 0;
  box-shadow: none;

  &--active {
    border: 1px solid black;
  }

  &--inactive {
    border: 1px solid lightgray;
  }

  &__word {
    font-weight: bold;
    color: black;
    font-size: 1.1rem;
  }

  &__divider {
    margin: 1.5rem 0;
    border: solid;
    border-width: thin 0 0 0;
    border-color: rgba(0, 0, 0, 0.12);
  }

  &__switcher-wrapper {
      white-space: nowrap;
  }
}
.tag:not(body) {
  padding: 0;
  margin-left: .5rem;
}
/deep/ .taginput .taginput-container.is-focusable {
  border: 0;
  box-shadow: none;
}
</style>
