<template>
  <div class="container">
    <h1>Escrever nova frase</h1>
    <h5>Escreva uma nova frase e selecione a intenção</h5>
    <InputGroup @onSendPhrase="generateWords" />
    <p v-if="words.length > 0" class="description">
      Selecione as palavras que deseja adicionar variações
    </p>
    <WordCard
      @onChangeSwitch="updateVariation"
      @onUpdateEntity="updateEntity"
      :word="word"
      v-for="(word, index) in words"
      :key="index"
    />
    <b-button v-if="words.length > 0" @click="goToSuggestions" type="is-dark">
      Adicionar variações
    </b-button>
  </div>
</template>

<script>
import WordCard from '@/components/WordCard'
import InputGroup from '@/components/InputGroup'

export default {
  name: 'Home',
  components: { WordCard, InputGroup },
  data () {
    return {
      words: [],
      isQuestion: false,
      intent: null
    }
  },

  methods: {
    goToSuggestions () {
      this.$router.push({
        name: 'Suggestions',
        params: {
          isQuestion: this.isQuestion,
          intent: this.intent,
          words: this.words
        }
      })
    },
    generateWords (params) {
      this.words = params.words
      this.intent = params.intent
      this.isQuestion = params.isQuestion
    },
    updateVariation (params) {
      this.words.filter((word) => word.word === params.ref)[0].generate =
        params.value
    },
    updateEntity (params) {
      this.words.filter((word) => word.word === params.ref)[0].entity =
        params.entity
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@weni/unnnic-system/dist/unnnic.css";
@import "~@weni/unnnic-system/src/assets/scss/unnnic.scss";

h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

h1,
h5 {
  margin: 1rem 0;
}

.description {
  margin: 2rem 0 0;
}
</style>
