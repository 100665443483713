<template>
  <b-field class="input-group" grouped>
    <b-field expanded>
      <b-input v-model="phrase" expanded></b-input>
      <b-select v-model="language">
        <option>pt-br</option>
        <option>en-us</option>
      </b-select>
    </b-field>
    <b-field>
      <b-input v-model="intent" placeholder="intenção"></b-input>
    </b-field>
    <b-field>
      <b-button @click="sendPhrase" type="is-dark"> Enviar </b-button>
    </b-field>
  </b-field>
</template>

<script>
export default {
  name: 'InputGroup',
  data () {
    return {
      phrase: '',
      language: 'pt-br',
      intent: ''
    }
  },
  methods: {
    sendPhrase () {
      let words = this.phrase.split(' ')
      let isQuestion

      words.forEach((word) => {
        word.includes('?') ? (isQuestion = true) : (isQuestion = false)
      })
      words = words.map((word, index) => ({
        word: word.replace('?', ''),
        generate: false,
        entity: ''
      }))
      this.$emit('onSendPhrase', { words: words, intent: this.intent, isQuestion: isQuestion })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  display: flex;
  margin: 1rem 0;
}
</style>
