<template>
  <div class="phrase-card">
    <div class="phrase-card__phrase">
      <span>{{ $attrs.item.text }}</span>
    </div>
    <div>
      <unnnic-button-icon @click="deletePhrase" size="small" icon="close-1" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhraseCard',
  methods: {
    deletePhrase () {
      this.$emit('onDeletePhrase', this.$attrs.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.phrase-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid lightgrey;

  &:first-child {
    margin-top: 0;
  }

  &__phrase {
    font-weight: normal;
  }

}
</style>
