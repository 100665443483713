<template>
  <div class="container">
    <transition name="fade">
        <div class="loading" v-show="loading">
          <b-icon icon="cached" size="is-small" /> Carregando
        </div>
    </transition>
    <h1>Adicionar variações de palavras</h1>
    <h5>Selecione as variações que deseja adicionar para cada palavra</h5>
    <SuggestionCard
      @onUpdateVariations="updateVariations"
      :suggestion="suggestion"
      v-for="(suggestion, index) in suggestions"
      :key="index"
    />
    <b-button @click="goToGeneratedPhrases" type="is-dark">
      Gerar frases
    </b-button>
  </div>
</template>

<script>
import SuggestionCard from '@/components/SuggestionCard'
import axios from 'axios'

export default {
  name: 'Suggestions',
  components: { SuggestionCard },
  data () {
    return {
      suggestions: [],
      loading: false
    }
  },
  mounted () {
    if (this.$route.params) {
      this.loading = true
      axios
        .post(
          'https://chatguy-nlp.weni.ai/suggest_words/',
          {
            isquestion: this.$route.params.isQuestion,
            intent: this.$route.params.intent,
            texts: this.$route.params.words
          }
        )
        .then((response) => {
          this.suggestions = response.data
        })
        .catch((error) => alert(error))
        .finally(this.loading = false)
    }
  },
  methods: {
    goToGeneratedPhrases () {
      this.$router.push({
        name: 'GeneratedPhrases',
        params: {
          isQuestion: this.$route.params.isQuestion,
          intent: this.$route.params.intent,
          words: this.suggestions
        }
      })
    },
    updateVariations (params) {
      this.suggestions.filter((e) => e.word === params.word)[0].suggestions =
        params.suggestionGroup
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@weni/unnnic-system/dist/unnnic.css";
@import "~@weni/unnnic-system/src/assets/scss/unnnic.scss";

h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

h1,
h5 {
  margin: 1rem 0;
}

.description {
  margin: 2rem 0;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: #00ded2;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
