<template>
  <div class="container">
    <h1>Frases geradas</h1>
    <div class="wrapper">
      <div>
        <h5>Lista de frases geradas a partir das variações selecionadas</h5>
        <span class="results">{{ phrasesCounter }}</span>
      </div>
      <div>
        <b-button
          @click="downloadFile"
          icon-left="tray-arrow-down"
          type="is-dark"
        >
          Fazer download
        </b-button>
      </div>
    </div>
    <div class="list-group-wrapper">
      <transition name="fade">
        <div class="loading" v-show="loading">
          <b-icon icon="cached" size="is-small" /> Carregando
        </div>
      </transition>
      <div class="list-group" id="infinite-list">
        <PhraseCard
          @onDeletePhrase="deletePhrase"
          :item="item"
          v-for="(item, index) in items"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PhraseCard from '@/components/PhraseCard'
import axios from 'axios'

export default {
  name: 'GeneratedPhrases',
  components: { PhraseCard },
  data () {
    return {
      phrases: [],
      loading: false,
      nextItem: 1,
      items: [],
      responseJSON: []
    }
  },
  computed: {
    phrasesCounter () {
      if (this.items.length === 1) {
        return '1 frase'
      } else {
        return `${this.items.length} frases`
      }
    }
  },
  mounted () {
    const listElm = document.querySelector('#infinite-list')
    listElm.addEventListener('scroll', (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.loadMore()
      }
    })

    this.loadMore()
  },
  methods: {
    loadMore () {
      this.loading = true
      axios
        .post(
          'https://chatguy-nlp.weni.ai/suggest_sentences/',
          {
            isquestion: this.$route.params.isQuestion,
            intent: this.$route.params.intent,
            texts: this.$route.params.words
          }
        )
        .then((response) => {
          this.responseJSON = response.data
          this.items = response.data.rasa_nlu_data.common_examples
          this.loading = false
        })
        .catch((error) => alert(error))
        .finally(this.loading = false)
    },
    deletePhrase (phrase) {
      this.items.splice(phrase, 1)
    },
    downloadFile () {
      const data = JSON.stringify(this.responseJSON)
      const blob = new Blob([data], { type: 'text/plain' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')
      a.download = 'file.json'
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      a.dispatchEvent(e)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@weni/unnnic-system/dist/unnnic.css";
@import "~@weni/unnnic-system/src/assets/scss/unnnic.scss";

h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

h1,
h5 {
  margin: 0.5rem 0;
}

.results {
  color: lightgrey;
  font-weight: bold;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-group-wrapper {
  position: relative;
}
.list-group {
  overflow: auto;
  height: 60vh;
  margin-top: 1rem;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: #00ded2;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
